import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="Blog" />

      <div className="flex flex-col gap-8 text-lg">
        <h1 className="mb-10">Blog</h1>

        <ol className="flex flex-col">
            {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
                <li key={post.fields.slug} className="mb-3">
                    <article
                      className="flex flex-col sm:flex-row justify-between sm:items-center"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <Link to={"/blog" + post.fields.slug} itemProp="url" className="font-bold text-lg">
                        {title}
                      </Link>

                      <span className="text-xs no-underline hover:no-underline">{post.frontmatter.date} • {post.fields.readingTime.text}</span>
                    </article>
                </li>
            )
            })}
        </ol>
      </div>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
